import React from "react"
import Img from "gatsby-image"
import Layout from "../../components/root/Layout"
import Seo from "../../components/root/Seo"
import Card from "../../components/elements/Card"

export default function MeetTheMentors({ data }) {
  const mentors = data.allPrismicMentor.nodes

  return (
    <Layout>
      <Seo title="Meet the Mentors" />
      <div className="">
        <div className="container py-8 md:py-16 lg:py-24">
          <h1 className="text-4xl md:text-5xl leading-tight text-center font-bold mb-3">
            Meet some of our mentors
          </h1>
          <div className="mb-5">
            {mentors.map(({ data: { mentorprofileportrait, mentorname, role, mentordesc } }) => (
              <div className="mb-8">
             <Card>
             <div className="grid grid-cols-1 md:grid-cols-4 ">
               <Img
                 style={{ zIndex: 1 }}
                 fluid={
                  mentorprofileportrait.localFile.childImageSharp
                     .fluid
                 }
                 alt={`${mentorname.text}'s portrait`}
                 className={`w-32 h-32 rounded-full mx-auto`}
               />
               <div className="md:col-span-3">
                 <h3 className="text-2xl font-bold">{mentorname.text}</h3>
                 <h4 className="text-xl text-brand-blue">{role.text}</h4>
                 <p className="text-base md:text-lg">{mentordesc.text}</p>
               </div>
             </div>
           </Card>
           </div>
            ))}
          </div>
          
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  {
    allPrismicMentor {
      nodes {
        data {
          mentordesc {
            text
          }
          mentorname {
            text
          }
          mentorprofileportrait {
            localFile {
              childImageSharp {
                fluid(maxWidth: 380) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
          role {
            text
          }
        }
      }
    }
  }
`
